.plugin-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.plugin-list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.bottom-spacing {
  margin-bottom: 12px;
}

.plugin-error {
  color: #ff0000;
}

.plugin-label {
  margin-left: 8px;
}

.loading {
  margin-bottom: 12px;
}

/* From: https://stackoverflow.com/a/28074607/6620612 */
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-right: 12px;
  padding-left: 12px;
  justify-content: space-evenly;
  margin-bottom: 12px;
}

.buttons button {
  flex: 1;
  margin-right: 12px;
  margin-right: 12px;
}
