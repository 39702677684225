.dialog {
  top: 12px;
  z-index: 8;
  background-color: #fbefd5;
  box-shadow: 0 0 1em black;
  max-width: 400px;
}

.dialog h3 {
  margin-top: 0px;
}

.dialog textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}

.dialog menu {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.button {
  margin-bottom: 24px;
}

.top-spacing {
  margin-top: 34px;
}

.no-top-margin {
  margin-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.step {
  font-size: 1.5rem;
}

.break-word {
  word-break: break-word;
}
