.toggle-layers-control {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}

.heatmap-toggle {
  padding: 5px !important;
}

.heatmap-toggle span {
  background: rgba(255, 255, 255, 0.2) url(/img/heatmap.svg);
  background-blend-mode: lighten;
}

.grid-toggle {
  padding: 4px !important;
}

.grid-toggle span {
  background: rgba(255, 255, 255, 0.2) url(/img/grid.svg);
  background-blend-mode: lighten;
}

.labels-toggle {
  padding: 4px !important;
}

.labels-toggle span {
  background: rgba(255, 255, 255, 0.2) url(/img/labels.svg);
  background-blend-mode: lighten;
}

.toggle-off {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.toggle-off:hover {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
