.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  background-color: #fbefd5;
  overflow-x: hidden;
  width: 300px;
  padding: 12px;
  padding-top: 24px;
  font-size: 0.875rem;
  border: 1px solid #222222;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    top: initial;
    bottom: 0;
    left: 0;
    height: 45%;
    width: 100%;
    border-right: none;
    border-top: 1px solid #222222;
  }
}

.sidebar-header {
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: right;
}

.title {
  font-size: 1.75rem;
}

.close {
  display: block;
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: -16px;
}

.close:hover {
  filter: invert(40%);
}

.hide {
  position: fixed;
  display: block;
  font-size: 0px;
  border: none;
  cursor: pointer;
  top: 46%;
  left: 299px;
  z-index: 4;
  background-color: #fbefd5;
  padding: 12px 2px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  border-right: 2px solid #222222;
}

.hide img {
  transform: rotate(180deg);
}

@media only screen and (max-width: 600px) {
  .hide {
    top: calc(55% - 21px);
    left: 47%;
    padding: 2px 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom: none;
    border-left: 2px solid #222222;
  }

  .hide img {
    transform: rotate(90deg);
  }
}

.hide:hover {
  color: #888888;
}

.open {
  position: fixed;
  display: block;
  font-size: 0px;
  border: none;
  cursor: pointer;
  top: 46%;
  left: -1px;
  z-index: 3;
  background-color: #fbefd5;
  padding: 12px 2px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  border-right: 2px solid #222222;
}

.open img {
  transform: rotate(0deg);
}

@media only screen and (max-width: 600px) {
  .open {
    top: initial;
    bottom: -1px;
    left: 47%;
    padding: 2px 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom: none;
    border-left: 2px solid #222222;
  }

  .open img {
    transform: rotate(270deg);
  }
}

.cell-name-header {
  line-height: 1.75rem;
  word-wrap: break-word;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-modified-date {
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.subheader {
  margin-top: 0;
}
