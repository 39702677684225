.dialog {
  top: 12px;
  z-index: 8;
  background-color: #fbefd5;
  box-shadow: 0 0 1em black;
  max-width: 400px;
  min-width: 300px;
  min-height: 400px;
}

.dialog[open] {
  display: flex;
  flex-direction: column;
}

.dialog h3 {
  margin-top: 0px;
}

.dialog menu {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.button {
  margin-bottom: 24px;
}