.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.map-wrapper {
  position: relative;
  height: 100%;
}

.map-wrapper-sidebar-open .map-container {
  width: calc(100% - 300px);
  left: 300px;
}

@media only screen and (max-width: 600px) {
  .map-wrapper-sidebar-open .map-container {
    height: calc(100% - 45%);
    width: 100%;
    left: 0;
    bottom: 45%;
  }
}
